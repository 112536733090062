.snapper {
	// -webkit-scroll-snap-type: proximity;
	// -webkit-scroll-snap-points-x: repeat(100%);
}
.breadcrumb-component {
	float: left;
	padding-top: 20px;
	padding-left: 25px;
	@media #{$xsmall} {
		padding-left: 0;
	}



	&.recipes {
		position: fixed;
		z-index: 1000;
		padding-left:23px;
		@media #{$xsmall} {
			display: none;
		}
	}
	&.subpage, &.bcmembers {
		position: relative;
		z-index: 1000;
		// padding-left: 0;
		color: #fff;
		@media #{$xsmall} {
			display: none;
		}
	}

	@media #{$xsmall} {
		text-align: center;
		font-size: 19px;
		padding-top: 60px;
	}
	&.sticky{
		position: fixed;
		top: 0;
		z-index: 1000;
		width: 60%;
		//background: #fff;
		padding-bottom: 15px;

		@media #{$xsmall} {
			position: inherit !important;
			width: 100%;
			padding-bottom: 0;

		}

	}
	ul, li{
		list-style-type: none;
		font-family: 'telefon';
		text-transform: uppercase;
		font-size: 22px;
		font-weight: 700;
		@media #{$xsmall-min} {
			font-size: 19px;
		}

	}
	a {
		text-decoration: none;
		border-bottom: 2px solid #000;
		transition: all 0.2s ease-in-out;
		&:hover {
			border-bottom: 2px solid transparent;
			transition: all 0.2s ease-in-out;
		}
	}
	&.subpage, &.bcmembers{
		a {
			border-bottom: 2px solid #fff;
			&:hover {
				border-bottom: 2px solid transparent;
				transition: all 0.2s ease-in-out;
			}
		}
	}
}

header.main-header {
    position: fixed;
    height: 70px;
    width: 100%;

		padding: 25px 25px 0 25px;
    z-index: 999;
		.modal-open & {
			z-index: -1;
			display: none;
		}
    &.sticky{
        background: #fff;
        @media #{$xsmall} {
            background: none;
        }

    }

    @media #{$xsmall} {
    	text-align: center;
      position: relative;
      top:0;
      height: 50px;
      margin-bottom: -50px;
      padding-top: 12px;
      z-index: 99999;

    }
    // @media #{$iphone} {
    //   display: none;
    // }

		.logo {
			min-width: 200px;
      max-width: 200px;
			display: inline-block;
			.oldcampaign & {
				display: none;
			}
      svg {
        max-width: 100%;
        max-height: 40px;
      }
      .logo-text {
        transition: fill 0.8s ease-in-out;
      }
      .fp-viewing-start & {
        .logo-text {
          // transition: fill 0.8s ease-in-out;
          fill: #fff;
        }
      }





			@media #{$xsmall} {

				min-width: 50px;
				max-width: 200px;
				margin-bottom: 20px;
			}
      @media #{$oldiphone} {
        display: none;
      }

		}

    .social-wrapper {
    	float: right;
    	@media #{$xsmall} {
    		float: none;
        display: none;

    	}
      // @media #{$small} {
      //   .fb-share-button span {
      //     height: 27px !important;
      //   }
      // }
      @media #{$oldiphone} {
        display: none;
      }

    }
}

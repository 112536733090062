// Add your settings here

.stripped-list {
	list-style-type: none;
}
.d-ib {
	display: inline-block;
}

.mt-5 {
	margin-top: 5px !important;
}
.xs-mt60 {
	@media #{$xsmall} {
		margin-top: 60px !important;
	}
}
.xs-mt80 {
	@media #{$xsmall} {
		margin-top: 80px !important;
	}
}

.hidden-xs {
	@media #{$xsmall} {
		display: none;
	}
}

.hidden-sm {
	@media #{$small} {
		display: none;
	}
}

.hidden-md {
	@media #{$medium} {
		display: none;
	}
}

.hidden-lg {
	@media #{$large} {
		display: none;
	}
	@media #{$large-min} {
		display: none;
	}
}

h1,h2 {
  font-family: $telefon;
  text-transform: uppercase;
}

h1, h2{
	font-size: 22px;
	line-height: 1.2em;
	font-weight: 700;
	@media #{$xsmall} {
		font-size: 20px;
	}
	&.hero-header {
		font-size: 38px;
		margin-bottom: 20px;
		line-height: 1.3em;
	}
}
p{
	font-family: $cyntho_regular;
	font-size: 14px;
	margin-bottom: 30px;

	&.main-paragraph {
		font-size: 16px

	}
	@media #{$xsmall} {
		font-size: 14px;
		line-height:1.6em;
	}
}

.content-intro p{
	font-family: $telefon;
	font-size: 22px;
	line-height:1.3em;
}
ul, li {
	font-size: 14px;
}

.logo-holder{
	font-size: 150px;
	@media #{$xsmall} {
		font-size: 70px;
	}
}

.custom-glyph {
  font-family: "brodinstitutet-glyphs";
}

/* This makes the letter to be switched out visible in the markup. */
.hidden-glyph {
  opacity: 0;
  font-size: 0;
}

/* Here we add the glyphs made available by the fontello.com font files. The kerning is unscientific but gets the job done. */
// .glyph-e:before { content: '\e809'; letter-spacing: -0.07em; margin-left: -0.1em; }
// .glyph-f:before { content: '\e80A'; letter-spacing: -0.1em; margin-left: -0.1em; }
// .glyph-m:before { content: '\e80B'; letter-spacing: 0.05em; margin-left: 0.07em; }
// .glyph-o:before { content: '\e80C'; letter-spacing: 0.04em; margin-left: 0.05em; }
// .glyph-v:before { content: '\e80D'; letter-spacing: -0.20em; margin-left: -0.05em; }
// .glyph-oe:before { content: '\e80E'; letter-spacing: 0.04em; margin-left: 0.05em; }

.glyph-arrow-down:before { content: '\e800'; } /* '' */
.glyph-arrow-left:before { content: '\e801'; } /* '' */
.glyph-arrow-right:before { content: '\e802'; } /* '' */
.glyph-close:before { content: '\e803'; } /* '' */
.glyph-logo:before { content: '\e804'; } /* '' */
.glyph-play:before { content: '\e805'; } /* '' */
.glyph-snapchat:before { content: '\e806'; } /* '' */
.glyph-bread-the-future-logo:before { content: '\e807'; } /* '' */
.glyph-fb:before { content: '\e808'; } /* '' */
.glyph-e:before { content: '\e809'; } /* '' */
.glyph-f:before { content: '\e80a'; } /* '' */
.glyph-m:before { content: '\e80b'; } /* '' */
.glyph-0:before { content: '\e80c'; } /* '' */
.glyph-v:before { content: '\e80d'; } /* '' */
.glyph-oe:before { content: '\e80e'; } /* '' */

/* Here we add the glyphs made available by the fontello.com font files. The kerning is unscientific but gets the job done. */
.glyph-e:before { content: '\e809'; letter-spacing: -0.07em; margin-left: -0.1em; }
.glyph-f:before { content: '\e80A'; letter-spacing: -0.1em; margin-left: -0.1em; }
.glyph-m:before { content: '\e80B'; letter-spacing: 0.05em; margin-left: 0.07em; }
.glyph-o:before { content: '\e80C'; letter-spacing: 0.04em; margin-left: 0.05em; }
.glyph-v:before { content: '\e80D'; letter-spacing: -0.20em; margin-left: -0.05em; }
.glyph-oe:before { content: '\e80E'; letter-spacing: 0.04em; margin-left: 0.05em; }






.icon-bread-the-future-logo:before { content: '\e801'; } /* '' */


.c-heading_main {
  font-size: 38px;
  margin-bottom: 20px;
  @media #{$xsmall} {
    font-size: 24px;
  }
}

// CAROUSEL
/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
// .slick-slide {
//     float: left;
//     height: 100%;
//     min-height: 1px;

    
 
//     img {
//         display: block;
//         max-width: 100%;
//         margin:0 auto;
//     }
//     &.slick-loading img {
//         display: none;
//     }

//     display: none;

//     &.dragging img {
//         pointer-events: none;
//     }

//     .slick-initialized & {
//         display: block;

//     }

//     .slick-loading & {
//         visibility: hidden;
//     }

//     .slick-vertical & {
//         display: block;
//         height: auto;
//         border: 1px solid transparent;
//     }
// }
.slick-arrow.slick-hidden {
    display: none;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    &:focus {
    	outline: none;
    }
    img {
        display: block;
        max-width: 100%;
        margin:0 auto;
    }
    &.slick-loading img {
        display: none;
    }

    // display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    top: calc(50vh - 13px);
    @media #{$small} {
    	top: 12vh;
    }
    @media #{$xsmall} {
    	top: calc(50vh - 13px);
    }
    z-index: 999;
    display: block;
    padding: 0;
    margin-top: -10px\9; /*lte IE 8*/
    transform: translateY(-50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    color: #000;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: inherit;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev:hover:before,.slick-next:hover:before {
    font-size: 48px;    
} 


.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
	color: inherit;
	font-family: 'brodinstitutet-glyphs';
	font-size: 50px;
    font-weight: 400;
    line-height:1;
    transition: all 0.2s ease-in-out;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

.slick-prev {
    left: 23px;
}

.slick-prev:before {
    content: "\e801";
}
[dir='rtl'] .slick-prev:before {
    content: "\e801";
}

.slick-next {
    right: 23px;
}



.slick-next:before {
    content: "\e802";
}



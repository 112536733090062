/* Font faces */
$telefon: 'telefon';
$cyntho_regular: 'cyntho_slab_regular';

$baseFontFamily: $cyntho_regular, Helvetica, Arial;
// Colors
$white : #ffffff;
$black : #000000;
$darkGrey : #333333;

/* Break Points */
$xsmall: "screen and (max-width: 375px)";
$xsmall-min: "screen and (min-width: 375px)";
$small: "screen and (max-width: 768px)";
$small-min: "screen and (min-width: 768px)";
$medium: "screen and (max-width: 1280px)";
$medium-min: "screen and (min-width: 1279px)";
$large: "screen and (max-width: 1440px)";
$large-min: "screen and (min-width: 1439px)";

$xsmallheight: "screen and ( max-height: 400px)";
$smallheight: "screen and ( max-height: 530px)";
$mediumheight: "screen and ( max-height: 660px)";

/* Portrait and Landscape */
$ipad: "only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)";
/* Portrait */
$iphone: "only screen and (min-device-width: 320px) and (max-device-width: 568px)and (-webkit-min-device-pixel-ratio: 2)and (orientation: portrait)";


$oldiphone: "only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)";

// Use this file to define your font families.
// For example:
@font-face {
    font-family: 'telefon';
    src: url('../fonts/telefon/telefon_web_normal.eot');
    src: url('../fonts/telefon/telefon_web_normal.eot?#iefix') format('embedded-opentype'),
         url('../fonts/telefon/telefon_web_normal.woff') format('woff'),
         url('../fonts/telefon/telefon_web_normal.ttf') format('truetype'),
         url('../fonts/telefon/telefon_web_normal.svg#telefon_web_regular') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
  font-family: 'brodinstitutet-glyphs';
  src: url('../fonts/custom/brodinstitutet-glyphs.eot?93753102');
  src: url('../fonts/custom/brodinstitutet-glyphs.eot?93753102#iefix') format('embedded-opentype'),
       url('../fonts/custom/brodinstitutet-glyphs.woff?93753102') format('woff'),
       url('../fonts/custom/brodinstitutet-glyphs.ttf?93753102') format('truetype'),
       url('../fonts/custom/brodinstitutet-glyphs.svg?93753102#brodinstitutet-glyphs') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'cyntho_slab_regular';
    src: url('../fonts/cyntho/CynthoSlabPro-Regular-webfont.eot');
    src: url('../fonts/cyntho/CynthoSlabPro-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/cyntho/CynthoSlabPro-Regular-webfont.woff2') format('woff2'),
         url('../fonts/cyntho/CynthoSlabPro-Regular-webfont.woff') format('woff'),
         url('../fonts/cyntho/CynthoSlabPro-Regular-webfont.ttf') format('truetype'),
         url('../fonts/cyntho/CynthoSlabPro-Regular-webfont.svg#cyntho_slab_proregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello/fontello.eot?46258260');
  src: url('../fonts/fontello/fontello.eot?46258260#iefix') format('embedded-opentype'),
       url('../fonts/fontello/fontello.woff?46258260') format('woff'),
       url('../fonts/fontello/fontello.ttf?46258260') format('truetype'),
       url('../fonts/fontello/fontello.svg?46258260#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  // width: 1em;
  // margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  // margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}




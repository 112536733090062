.hero-wrapper{
	margin-top: -53px;
	position: relative;
	min-height: 500px;

	@media #{$xsmall} {
    	min-height: 380px;
    }

    &.breadthefuturepage {
    	@media #{$xsmall} {
    		background-position: 20% center !important;
    	}
    }

	h1 {
		position: absolute;
		top: 50%;
		transform: translateY(-45%);
		color: #fff;
		font-size: 38px;
		@media #{$xsmall} {
        	transform: translateY(calc(-60% + 53px));
        	font-size: 30px;
    	}
	}
}
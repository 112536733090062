
body {
	background: #f6f5f7;
    font-family: $baseFontFamily;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.oldcampaign {
		background: #fff;
	}
	&.members-page {
		background:url('../images/BreadTheFuture_Member-bg.jpg') no-repeat;
		background-size:cover;
		background-attachment: fixed;
	}

}

a{
	color: inherit;
	text-decoration: none;
}

img {
	max-width: 100%;
}

#loader {
	z-index: 9999999;
	opacity: 1;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #fff;
	transition: opacity 0.7s ease-in-out;
	.loaded & {
		opacity: 0;
		transition: opacity 0.7s ease-in-out;
	}

}

.hidden {
	transition: opacity 0.7s ease-in-out;
	opacity: 0;
}

.recipes{
	@media#{$xsmall} {
		margin-top:-50px;
	}
}
.recipes-wrapper {
	padding-top: 170px;
	min-height: 100vh;
	// height:1060px;
	z-index:999;
	position: relative;
	&[recipe-index='1']{
		background: #e5bfa6;
		&:after {
			background: #f5d8c3;
			.slick-active &{
				z-index:99;
			}
		}
	}
	&[recipe-index='2']{
		background: #e5bfa6;
		&:after {
			background: #f5d8c3;
			.slick-active &{
				z-index:99;
			}
		}
	}
	&[recipe-index='3']{
		background: #a2e2e3;
		&:after {
			background: #c1f7f7;
			.slick-active &{
				z-index:99;
			}
		}
	}
	&[recipe-index='4']{
		background: #c0d8b6;
		&:after {
			background: #dcedd9;
			.slick-active &{
				z-index:99;
			}
		}
	}
	&[recipe-index='5']{
		background: #c0d8b6;
		&:after {
			background: #dcedd9;
			.slick-active &{
				z-index:99;
			}
		}
	}
	&:after{
		content: "";
		position: absolute;
		height: 20%;
		width:100%;
		bottom:0;
		left: 0;

		
		@media#{$small-min} {
			height: 20%;
		}
		@media#{$small} { 
			height: 60%;
		}
		@media#{$xsmall} { 
			height: 75%;
		}

	}
	.recipes-image{
		position:relative;
		z-index:99;
		height: 100vh;
		.recipes-image-holder{
			position:absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			@media#{$small} { 
				top: 100px;
			}
		}
		
	}
	.recipes-content {
		position:relative;
		z-index:99;
		opacity: 0;

		&.fadeout {
			opacity: 1;
			@include animation(1s, 0.5s, 1, fadeout);
		}

		@media#{$xsmall-min} {
			padding-top: 0;
			top: 160px;
			transform: none;
		}

		@media#{$small-min} {
			top: 50vh;
			transform: translateY(-50%);
		}
		
	
		@media#{$medium-min} {
			top: 50vh;
			transform: translateY(-50%);

		}
		@media#{$small} {
			top: 160px !important;
			transform: none;
			.recipes-content-instructions {
				margin-top: 30px;
			}
		}
		@media#{$xsmall} {
			top: 0 !important;
			padding-top: 100px;
			.recipes-content-instructions {
				margin-top: 30px;
			}
		}
	}
	.fadeout, .recipes-image-slice{
		opacity:0;
	}
}

// Animations
.slick-active {
	.recipes-image{
		.recipes-image-slice {
			text-align: center;
			opacity:0;
			position: relative;

			img{
				@media#{$small-min} {
					max-width: 390px;
				}
				@media#{$small} { 
					max-width: 340px;
				}

				@media#{$xsmall} { 
					max-width: 100%;
				}
				
				
				@media#{$mediumheight} {
					max-width: 320px;
				}
				@media#{$smallheight} {
					max-width: 280px;
				}
				@media#{$xsmallheight} {
					max-width: 200px;
				}
				
			}

			&.recipe-1 {
				margin-bottom: -25px;
			}

			&--pos1 {
				z-index: 9999;

				&.recipe-3 {
					margin-bottom: -25px;
				}

				&.recipe-4 {
					margin-bottom: -25px;
				}

				&.initAnim {
					@include animation(0.2s, 0.3s, 1, slidedown);
				}

				img {
					@include animation(0.2s, 3s, infinite, floating);
				}

				&.fadeout {
					opacity: 1;
					@include animation(0.2s, 0.3s, 1, slideupout);
				} 	
				
			}
			&--pos2 {
				z-index: 9998;
				&.recipe-4 {
					margin-bottom: -75px;
				}
				&.initAnim {	
					@include animation(0.3s, 0.3s, 1, slidedown);
				}
				img{
					@include animation(0.4s, 3s, infinite, floating);
				}
				&.fadeout {
					opacity: 1;
					@include animation(0.2s, 0.2s, 1, slideupout);
				} 
			}
			&--pos3 {
				z-index: 9997;
				&.recipe-4 {
					margin-bottom: -25px;
				}
				&.initAnim {	
					@include animation(0.4s, 0.4s, 1, slidedown);
				}
				img{
					@include animation(0.6s, 3s, infinite, floating);
				}		
				&.fadeout {
					z-index: 9996;
					opacity:1;
					@include animation(0.3s, 0.2s, 1, slideupout);
				} 
			}
			&--pos4 {
				z-index: 9995;
				&.recipe-4 {
					margin-bottom: -55px;
				}	
				&.initAnim {
					@include animation(0.5s, 0.4s, 1, slidedown);
				}
				img{
					@include animation(0.8s, 3s, infinite, floating);
				}	
				&.fadeout {
					opacity:1;
					@include animation(0.4s, 0.2s, 1, slideupout);
				} 
			}
			&--pos5 {
				z-index: 9994;
				&.recipe-1 {
					margin-top: -30px;
    				margin-bottom: -10px;
				}
				&.recipe-3 {
					margin-bottom: -25px;
				}
				&.recipe-4 {
					margin-bottom: -20px;
				}

				&.initAnim {	
					@include animation(0.7s, 0.4s, 1, slidedown);
				}
				img {
					@include animation(0.2s, 3s, infinite, floating);
				}
				&.fadeout {
					opacity:1;
					@include animation(0.5s, 0.2s, 1, slideupout);
				} 
			}
			&--pos6 {
				z-index: 9993;
				&.recipe-4 {
					margin-bottom: -15px;
				}
				&.recipe-5 {
					margin-bottom: -20px;
				}
				&.initAnim {	
					@include animation(0.7s, 0.4s, 1, slidedown);
				}
				img {
					@include animation(0.4s, 3s, infinite, floating);
				}
				&.fadeout {
					opacity:1;
					@include animation(0.6s, 0.2s, 1, slideupout);
				} 
			}

			&--posbottom{
				z-index: 1;
				&.initAnim {
					@include animation(0.7s, 0.4s, 1, fadein);
				}
				&.fadeout {
					opacity:1;
					//border:2px solid pink !important;
					@include animation(0.8s, 0.2s, 1, fadeout);
				} 
			}
		}
	}

	.recipes-content{
		@include animation(0.5s, 0.4s, 1, fadein);
		&.fadeout {
			opacity: 1;
			@include animation(0.7s, 0.4s, 1, fadeout);
		}
	}
}


.twitter-share-button {

}

.fb_iframe_widget {
	line-height: 10px;
	margin-left: 10px;
	// @media #{$ipad} {
	// 	top: -7px;
	// }
}

// Twitter feed

.twitter-feed {

	ul {
		list-style-type: none;
	}
	// margin-bottom: 20px;
	padding: 0;
}


.snap-wrapper {
	margin: 100px auto;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display:inline-block;

}

a.btn {
	background: transparent;
  color: #000;
  padding: 15px 35px;
  font-family: "telefon";
  border: 1px solid #fff;
  transition: all 0.2s ease-in-out;
  text-align: center;
  text-decoration: none !important;
	font-size: 22px;
	.oldcampaign & {
		background: #fff;
		text-transform: uppercase;

	}
	&--ghost {
		border: 2px solid #000;
		margin-right: 20px;
		@media #{$xsmall} {
			margin-bottom: 30px;
		}


		&:hover {
			// color: #000 !important;
			background: #000 !important;
		}

	}
  @media #{$xsmall} {
		display: block;
		width: 100%;
	}
	&:hover {
		background:none;
		color:#fff;
	}
}

.link-border {
	font-family: 'telefon';
	font-size: 22px;
	line-height: 22px;
	border-bottom: 2px solid #000;
	transition: border-bottom 0.4s;
	@media #{$small} {
		display: inline-block;
		margin-top: 30px;
	}

	&:hover {
		border-bottom: 2px solid transparent;
	}

}

html.modal-open {
 	//height: 100%;
 	overflow: hidden;
 	position: relative;
}

body.modal-open {
    //height: 100%;
	overflow: hidden;
	position: relative;

	&.ios{
 		height: 100%;
 		position: fixed;
 	}

	&.hasScrollbar {
		padding-right: 15px;
	}
}

// .wrapper.modal-open {
//     //height: 100%;
// 	overflow: hidden;
// 	position: relative;

// }

.modal {
	width: 100%;
	height: 100%;
	background: #000;
	left: 0;
	position: fixed;
    z-index: 2000;

	&.open {
		-webkit-overflow-scrolling: touch;
	}
}

.modal {
	//visibility: hidden;
	display: none;

	&.open {
		//visibility: visible;
		display: block;
	}

}

.modalOverlay {
	visibility: hidden;
	display: none;
	background: rgba(255, 255, 255, 0);
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	transition: background 0.5s ease;
	overflow: scroll;
	
	&.open {
		visibility: visible;
		display: block;	
		z-index: 1500;
		background: rgb(255, 255, 255);
		background: rgba(255, 255, 255, 0.9);
	}	

}

.modalClose {
	visibility: hidden;

	&.open {
		visibility: visible;
		z-index: 2500;
		background: none;
		border: none;
		width: 63px;
		height: 63px;	
		position: fixed;
		top: 20px;
		right: 17px;
		color: #fff;
		font-size: 50px;
		transition: all 0.2s ease-in-out;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	  	
	  	&:hover{
	  		cursor:pointer;
	  		transition: all 0.2s ease-in-out;
	  		font-size: 48px;
	  		margin-bottom:-2px;
	  	}
	}
	
}
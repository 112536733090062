@include keyframe(floating){
    0% {
    	opacity:1;
    	transform: translateY(0);
   	}
    65% {
    	opacity:1;
    	transform: translateY(10px);
    }
    100% {
    	opacity:1;
    	transform: translateY(0);
    }
}

@include keyframe(slidedown) {
	 0% {
        opacity:0;
        transform: translateY(-20px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }

}

@include keyframe(slideup) {
	 0% {
        opacity:0;
        transform: translateY(20px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
}

@include keyframe(slideupout) {
	 0% {
        opacity:1;
        transform: translateY(0);
    }
    100% {
        opacity:0;
        transform: translateY(-80px);
    }
}

@include keyframe(fadein) {
	 0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@include keyframe(fadeout) {
	 0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}
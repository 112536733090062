.content-wrapper {
	padding-top: 65px;
	a {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
		}
	}


	@media #{$small-min} {
		.members-page & {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);			
		}

	}
	.members & {
	    color: #fff;
	    padding-top:135px;
	}
	.content-intro {
		@media #{$small} {
			max-width: auto;
		}

		max-width: 360px;
		padding-right: 20px;
		@media #{$small} {
			max-width: none;
			padding-right: 0;
		}
		@media #{$xsmall} {
			padding-right: 0;
		}

	}
	.main-content {
		max-width: 680px;
		padding-left: 60px;
		padding-bottom: 100px;

		.graph-content {
			@media #{$xsmall-min} {
				p {
					max-width: 90%;
				}
			}
			
		}



		.members-page &, .sub-page & {
			max-width: none;
		}
		.prints-wrapper {
			img {
				&:first-child {
					margin-left: 0;
				}
				margin-left: 4px;
			}
		}

		@media #{$small} {
			max-width: none;
			padding-left: 0;
			padding-right: 0;
		}
		@media #{$xsmall} {
			padding-left: 0;
			padding-right: 0;
		}
		img {
			// margin: 0 0 20px 0;

			margin: 25px 0 40px 0;
			&.graph {
				max-height: 200px;
			}
		}
	}
}

// Members
.members{
	height:100vh;
	margin-top: -50px;
	@media #{$xsmall} {
    	margin-top: -50px;
    }
}






// YOUTUBE EMBEDS
.embed-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	margin-bottom: 25px;
}
.embed-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
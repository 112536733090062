// first section
section{
	&.main {
		position: absolute;
		text-align: center;
		color: #fff;
		max-width: 640px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
		@media #{$xsmall} {
			top: 43%;
			width:100%;
		}
	}
}
.fullpage-wrapper {
	height:400vh;
}
.video-wrapper {
	height: 100vh;
	background: #000;
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(0,0,0,0.6);
		z-index: 99;
	}

	@media #{$iphone}{
		.oldcampaign & {
			height: calc(100vh - 70px);
		}

	}

	p {
		font-size: 16px;
		@media #{$xsmall} {
			font-size: 13px;
		}
	}

}

.start-image{
	width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden;
  	backface-visibility: hidden;
  z-index: 0;
  background-image: url('../images/video/hero-fallback.jpg');
  background-repeat: no-repeat;
  background-size: cover;

	.oldcampaign & {
		background-image: url('../images/video/BreadTheFutureFallback.jpg');
	}

    @media #{$xsmall} {
		background-image: url('../images/video/hero-fallback.jpg');
		background-position: center top;
		.oldcampaign & {
			background-image: url('../images/video/BreadTheFutureFallback.jpg');
		}
		video{
			display:none;
		}
		@media (-webkit-video-playable-inline) {

			video{
				display:initial;
			}
		}
		// video{
		// 	display:none;
		// }
	}
	@media #{$ipad} {
		background-image: url('../images/video/hero-fallback.jpg');
		background-position: center top;
		.oldcampaign & {
			background-image: url('../images/video/BreadTheFutureFallback.jpg');
		}
		video{
			display:none;
		}
	}

	video{
		//display: block;
	    position: absolute;
	    // top: 50%;
	    // left: 50%;
	    min-width: 100%;
	    min-height: 100%;
	    width: auto;
	    height: auto;
	    z-index: -100;
	     // transform: translateX(-50%) translateY(-50%);
	    @media #{$ipad} {
			display:none;
			// transform: translateX(0) translateY(0);
		}

	}
}

.play-video {
	font-size: 24px;
	line-height: 1em;
	font-family: 'telefon';
	margin-top: 25px;
	@media #{$xsmall} {
		margin-top: 0;
	}


	span {
		font-size: 50px;
		vertical-align: middle;
		margin-right: 15px;
		transition: all 0.2s ease-in-out;
		&:hover {
			font-size: 48px;
			transition: all 0.2s ease-in-out;
			margin-bottom:-2px;
		}

	}
}


// ARROW

.scroll-arrow {
	position: absolute;
	bottom: 1%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 50px;
	color: #fff;
	transition: all 0.2s ease-in-out;
	z-index: 9999;
	.modal-open & {
		z-index: -1;
	}
	&:hover {
		font-size: 48px;
		transition: all 0.2s ease-in-out;
		margin-bottom:0px;
	}
}



// SECTION 2

.overview {
	height: 100vh;

	// display: block;
	// z-index: 99999;
	&.sticky {
		padding-top: 50px;
		@media #{$xsmall} {
			padding-top: 0;
		}
	}
}


// VIDEO SPOT

.video-content {
	max-height: 220px;
	min-height: 100%;

    video{

    	@media #{$ipad} {
			display:none;
			// transform: translateX(0) translateY(0);
		}
    }
	.grid-video-btn {
		position: absolute;
	    top: 50%;
	    left: 50%;
	    font-size: 50px;
	    color: #fff;
	    transform: translate(-50%, -50%);
	    z-index: 100;
	    transition: all 0.3s ease-in-out;
	    &:hover {
	    	cursor: pointer;
	    }
	}
}


// onePageScroll sections
.slider-section {
  height: 100vh;
	position: relative;
	// opacity: 0;
	transition: opacity 0.8s ease-in-out;
	.fp-enabled & {
		opacity: 0;
	}


	@media #{$xsmall} {
		padding: 0 10px;
		text-align: center;

		&.start-section {
			padding: 0;
		}
	}

	&.active {
		opacity: 1;
	}

	// &.boxbg {
	// 	&:after {
	// 		content:'';
	// 		display: block;
	// 		position: absolute;
	// 		bottom: 0;
	// 		right: 25px;
	// 		background-image: url('/images/slide2bg.png');
	// 		background-repeat: no-repeat;
	// 		background-size: cover;
	// 		height: 500px;
	// 		width: 879px;
	// 		@media #{$small} {
	// 			height: 100px;
	// 			width:100px;
	// 		}
	// 		@media #{$xsmall} {
	// 			height: 100px;
	// 			width:100px;
	// 		}
	// 	}
	// }
	&--innerwrapper {

		position: relative;
		top:50%;
		transform:translateY(-50%);

		@media #{$small} {
			top: 35%;
		}
		@media #{$small} {
			transform: none;
			top:15%;
		}
		// #slide3 & {
		// 	@media #{$xsmall} {
		// 		p {
		// 			padding-right: 30px;
		// 		}
		//
		//  }
		// }
		p {
			max-width: 480px;
			#slide3 & {
				@media #{$small} {
					padding-right: 30px;
				}
			}
		}
		&_left {
			padding-left: 130px;


			@media #{$xsmall} {
				padding-left: 20px;
				padding-right: 20px;
			}
			@media #{$small} {
				padding-left: 30px;
				padding-right: 30px;
			}


		}
		&_right {
			padding-right: 100px;
			@media #{$xsmall} {
				padding-right: 20px;
				padding-left: 20px;
			}
			@media #{$small} {
				padding-right: 20px;
				padding-left: 20px;
			}
		}
		&_center {
			text-align: center;
			a {
				margin: 0 10px;
				@media #{$xsmall} {
					margin: 25px 0;
				}

			}
			p {
				margin: 20px auto;
				max-width: 560px;
			}
		}

	}
}

// .wolt-logo {
// 	position: relative;
// 	top: 10px;
// 	margin-left: 25px;
// 	@media #{$xsmall} {
// 		margin-left: 0;
// 		top: 40px;
// 	}
// }

.box-animation {
	position: absolute;
	bottom: 0;
	right: 25px;
	max-width: 60vw;
	height: auto;
	@media #{$xsmall} {
		position: relative;
		max-width: 100%;
	}
}

$no-col: 12;
$gutter: 8px;

.container {
    margin: 0 auto;
    max-width: 1240px;

    padding: 0 $gutter;
    &--full {
        padding: 0 !important;
        max-width: 100% !important;
    }
    &--fullcontent {
        padding: 0 !important;
        margin:0 -4px;
        max-width: 100% !important;
        @media #{$xsmall} {
            margin: 0 -8px 0 0;
        }

    }
    &--fullgrid {
       padding: 0 4px;
    }
    &--wide {
        padding: 0 !important;
        max-width: 1440px;
    }
    &--doublegutter{
        padding: 0 50px;
        // max-width: 1020px;
    }
    .members-page &, .sub-page & {
        max-width: 1440px;
    }

    @media #{$xsmall} {
        padding: 0 15px;
    }
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.col {
    width: 100%;
    float: left;
    padding: 0 $gutter / 2;
    &--full {
        padding: 0 !important;
    }

    @media #{$xsmall} {
        padding: 0 7.5px;
    }

    @for $i from 1 through $no-col {

        &--#{$i} {
            width: calc((100% / #{$no-col}) * #{$i});
        }

        &--offset-#{$i} {
            margin-left: calc((100% / #{$no-col}) * #{$i});
        }

        &--offset-0 {
            margin-left: 0;
        }
    }

    @media #{$medium} {
        @for $i from 1 through $no-col {
            &--md-#{$i} {
                width: calc(((100% / #{$no-col}) * #{$i}));
            }

            &--md-offset-#{$i} {
                margin-left: calc((100% / #{$no-col}) * #{$i});
            }

            &--md-offset-0 {
                margin-left: 0;
            }
        }
    }

    @media #{$small} {
        @for $i from 1 through $no-col {
            &--sm-#{$i} {
                width: calc(((100% / #{$no-col}) * #{$i}));
            }

            &--sm-offset-#{$i} {
                margin-left: calc((100% / #{$no-col}) * #{$i});
            }

            &--sm-offset-0 {
                margin-left: 0;
            }
        }
    }

    @media #{$xsmall} {
        @for $i from 1 through $no-col {
            &--xs-#{$i} {
                width: calc(((100% / #{$no-col}) * #{$i}));
            }

            &--xs-offset-#{$i} {
                margin-left: calc((100% / #{$no-col}) * #{$i});
            }

            &--xs-offset-0 {
                margin-left: 0;
            }
        }
    }
}

.grid-items {
  margin-top: 15px;
}
.grid-item-component {
    height: 221px;
    margin-bottom: 8px;
    overflow: hidden;
    &:hover {
        cursor: pointer;
    }
    @media #{$small-min} {
        // height: 280px;
        height: calc(33vh - 26px);
    }

    &.sm-x2-height {
        @media #{$xsmall-min} {
            // height: 568px;
            height: calc(66vh - 44px);
        }
        @media #{$xsmall} {
            height: 450px;
        }

    }
    &.md-x2-height {
        @media #{$medium-min} {
            // height: 568px;
            height: calc(66vh - 44px);
        }
        @media #{$medium} {
            // height: 568px;
            height: calc(66vh - 44px);
        }



        @media #{$small} {
            height: 220px;
        }

    }
    a.bugslink {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
    }
    .component-inner {
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        transform: scale(1, 1);
        div.scalable {
            transition: all 0.3s ease-in-out;
            transform: scale(1, 1);
        }
        &:hover {
            div.scalable {
                transform: scale(1.05, 1.05);
                transition: all 0.3s ease-in-out;
                a {
                    transition: all 0.3s ease-in-out;
                    font-size: 44px !important;
                }
            }

        }

        height: 100%;

        .hover-image{
            opacity:0;
        }
        &:hover {
            .hover-image{
                opacity:1;
            }
        }

        &--white {
            background: #fff;

        }
        &--gray {
            background: #efefef;
            position: relative;

        }
        &--tweet {
            background: #eff8e2;

        }
        &--video {
            overflow: hidden;
            background-image: url("../images/video/BreadTheFutureFallback.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position:center top;
            @media #{$xsmall} {
                // background-image: url('../images/video/BreadTheFutureFallback.jpg');
                // background-position: center top;
                video{
                    display:none;
                }
            }
        }
        &--bugs {
            background: url('../images/bugs.gif') no-repeat;
            background-size: cover;
            background-position:center center;
            color: #fff;
            transform: scale(1, 1);

            &:hover {

                transform: scale(1.05, 1.05);
                transition: all 0.3s ease-in-out;
                .content {
                    transition: all 0.3s ease-in-out;
                    transform: translateY(-50%)scale(0.95,0.95);
                }

            }
        }
        &--cooks {
            background: url('../images/cooks.jpg') center center no-repeat;
            background-size: cover;
            &:before {
                left: 0;
                top: 10%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-left-color: #fff;
                border-width: 22px;

            }


            @media #{$small} {
                background: url('../images/cooks_header.jpg') center center no-repeat;
                background-size: cover;
                &:before {
                    right: 10%;
                    left: inherit;
                    top: -1px;
                    border-top-color: #fff;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    transform: translateX(-50%);
                }
            }
            @media #{$xsmall} {
                background: url('../images/cooks_header.jpg') center center no-repeat;
                background-size: cover;
                &:before {
                    left: 50%;
                    top: 0;
                    border-top-color: #fff;
                    border-left-color: transparent;
                    transform: translateX(-50%);
                }
            }



        }
        &--recepies {
            background: #f5d8c3;


            h2 {
                margin-bottom:30px;
            }
            img {
                max-width:50%;
            }

            &:hover{
                .recipes-image-slice{
                    transform: scale(1.5);
                    transition: all 0.2s ease-in-out;
                }
            }

            .recipes-image-slice{

                transition: all 0.2s ease-in-out;
                margin-bottom: -20px;
                position: relative;
                z-index: 990;
                &--pos1{
                    z-index: 999;
                    img{
                        @include animation(0.2s, 4.3s, infinite, floating);
                    }
                }
                &--pos2{
                     z-index: 998;
                    img{
                        @include animation(0.4s, 4.1s, infinite, floating);
                    }

                }
                &--pos3{
                    z-index: 997;
                    img{
                        @include animation(0.5s, 4s, infinite, floating);
                    }

                }
                &--pos4{
                    z-index: 996;
                    img{
                        @include animation(0.3s, 4.4s, infinite, floating);
                    }

                }
                &--pos5{
                    z-index: 995;
                    img{
                        @include animation(0.6s, 4s, infinite, floating);
                    }

                }
                &--pos6{
                    z-index: 994;
                    img{
                        @include animation(0.3s, 4s, infinite, floating);
                    }

                }

            }
        }
        &--facebook {
            background: #fff;
        }
        &--snapchat {
            background: #ffece4;

        }


        .content {
            text-align: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            padding:0 20px;
            transition: all 0.3s ease-in-out;
            backface-visibility: hidden;
            .icon{
                font-size: 60px;
            }
        }
    }
}


// Twitter

.message {
    min-height: 130px;
    p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top:0 !important;
    }
}
.content-twitter {
    transition: opacity 0.7s ease-in-out;
    p {
        font-size: 15px;
        // margin-top:10px;
        @media #{$medium} {
            font-size: 14px;
        }
        &.twitter-author {
            font-size: 16px;
        }
    }
}


.col--6{
    .overview & {
        width: 49.93333%;
    }

    @media #{$small} {
        .overview &{
            width: 100%;
        }

    }
}
